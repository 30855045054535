































































































































































import axios from 'axios';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

import DataCoreApiManager from '@/api/dataCoreApiManager';
import { Wallet } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiAlert, { UiAlertType } from '@/components/ui/UiAlert.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { getEmptyStringProps } from '@/utils/stringUtils';

// import { DataSvcSchema } from '../../../generated/data-svc';
import {
  DataCoreSvcDatasourcehdlCreateInput,
  DataCoreSvcDatasourcesGoogleSheetDataSource,
  // DataCoreSvcDatasourcesCreateGoogleSheetDataSource,
} from '../../../generated/data-core-svc/api';

@Component({
  components: {
    UiAlert,
    UiButton,
    UiDropdown,
    UiCheckbox,
    UiTooltip,
    UiLoading,
    UiSelect2,
    UiTextEdit,
    UiDataTable,
  },
  apollo: {
    // wallets: {
    //   query: gql`
    //     query GetWallets($orgId: ID!, $loadFairValue: Boolean) {
    //       wallets(orgId: $orgId, loadFairValue: $loadFairValue) {
    //         id
    //         connectionId
    //         exchangeConnectionId
    //         name
    //         type
    //         address
    //         addresses
    //         networkId
    //         enabledCoins
    //         networkId
    //         groupId
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       orgId: this.$store.state.currentOrg.id,
    //     };
    //   },
    //   loadingKey: 'isLoading',
    //   errorPolicy: 'ignore',
    // },
  },
})
export default class DataSourceCreate extends BaseVue {
  public isLoading = false;
  public errorMessage = '';

  public get orgServiceAccountEmail(): string {
    const orgId = this.$store.state.currentOrg?.id || '';
    return `org-${orgId}@bitwave-customer-data.iam.gserviceaccount.com`;
  }

  public get googleSheetsValidationMessage(): string {
    return `Please enter a Google Sheets URL with read access granted to your organization's service account (${this.orgServiceAccountEmail})`;
  }

  @Prop({ default: null })
  public dataSourceId!: string;

  @Prop({ default: false })
  public showExistingDataSourceCheckbox!: boolean;

  @Prop({ default: null })
  public schemaId!: string;

  public dataSourceType = 'Google Sheet';

  public dataSource!: { id: string; name: string } | null;

  public isValidInput = false;

  public apiBaseUrl = process.env.VUE_APP_API_URL;

  @Prop({ default: true })
  public readonly isCreateButtonVisible!: boolean;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Emit('onCreateDataSourceButtonClick')
  async onCreateDataSourceButtonClick() {
    const response = await this.createDataSource();
    return response;
  }

  public wallets: Array<Wallet> = [];

  generateName(prefix = 'DataSource') {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    return `${prefix}-${year}-${month}-${day}-${hour}-${minute}`;
  }

  public isCreatingDataSource = false;
  public loadedDataSources: any = [];

  public newDataSourceName = this.generateName();
  public newDataSourceSchema: { id: string; name: string } | null = null;
  public newDataSourceType = {
    name: 'Loaded',
    id: 'loaded',
  };

  public newDataSourceGoogleSheetUri = ''; // TODO: correct scope?
  public newDataSourceGoogleSheetRange = '';
  public newDataSourceGoogleSheetSkipLeadingRows = 1;
  public newDataSourceGoogleSheetJaggedRows = false;
  public newDataSourceBigQueryTable = '';
  public newDataSourceBigQueryDataSetId = '';
  public newDataSourceBigQueryProjectId = '';
  public newDataSourceBigQueryRegion = '';

  // GCS data source properties
  public newDataSourceGcsBucket = '';
  public newDataSourceGcsFileId = '';
  public newDataSourceGcsSkipLeadingRows = 1;
  public newDataSourceGcsJaggedRows = false;

  // CSV Upload data source properties
  public selectedCsvFile: File | null = null;
  public isUploadingCsv = false;
  public csvUploadComplete = false;
  public csvFilePointer: any = null;
  public newDataSourceCsvSkipLeadingRows = 1;
  public newDataSourceCsvJaggedRows = false;

  // public schemas: DataSvcSchema[] = [];

  public accountDataSource: any = [];
  public accountsList: any[] = [];

  public get validateDataSourceName() {
    return this.newDataSourceName.length >= 3;
  }

  public get validateDataSourceGoogleSheetUri() {
    const targetPrefix = 'https://'; // TODO: check for drive url like https://docs.google.com/spreadsheets ? or pass the bq error up?
    return this.newDataSourceGoogleSheetUri.substring(0, targetPrefix.length) === targetPrefix;
  }

  public get allowSchemaBeta() {
    return this.checkFeatureFlag('schema-beta', this.$store.getters.features);
  }

  public get hasImportV3Flag() {
    return this.checkFeatureFlag('import-v3', this.$store.getters.features);
  }

  public get availableDataSourceTypes() {
    const baseTypes = ['Google Sheet', 'Big Query'];

    if (this.hasImportV3Flag) {
      return [...baseTypes, 'GCS', 'CSV Upload'];
    }

    return baseTypes;
  }

  public get validateGcsBucket() {
    return this.newDataSourceGcsBucket.length > 0;
  }

  public get validateGcsFileId() {
    return this.newDataSourceGcsFileId.length > 0;
  }

  public get dataSourceTypes() {
    const options = [
      {
        name: 'Loaded',
        id: 'loaded',
      },
      {
        name: 'Blockchain',
        id: 'blockchain',
      },
      {
        name: 'Bitwave Data',
        id: 'bitwave-data',
      },
      {
        name: 'Transform',
        id: 'transform',
      },
    ];
    return options;
  }

  public validate() {
    if (this.dataSourceType === 'GCS') {
      this.isValidInput = this.validateDataSourceName && this.validateGcsBucket && this.validateGcsFileId;
    } else if (this.dataSourceType === 'CSV Upload') {
      this.isValidInput = this.validateDataSourceName && (this.csvUploadComplete || this.selectedCsvFile !== null);
    } else {
      this.isValidInput = this.validateDataSourceName;
    }
  }

  public onErrorAlertClosed() {
    console.log('Clearing error message');
    this.errorMessage = '';
  }

  public created() {
    // Initialize error message
    this.errorMessage = '';
  }

  public handleCsvFileChange(event: any) {
    const files = event.target.files;
    if (files && files.length > 0) {
      this.selectedCsvFile = files[0];
      this.csvUploadComplete = false;
      this.csvFilePointer = null;
      this.validate();
    }
  }

  public formatFileSize(bytes: number): string {
    if (bytes < 1024) {
      return bytes + ' bytes';
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else {
      return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    }
  }

  public async uploadCsvFile() {
    if (!this.selectedCsvFile) {
      this.errorMessage = 'Please select a CSV file to upload';
      return;
    }

    this.isUploadingCsv = true;
    this.errorMessage = '';

    try {
      // Step 1: Get a signed URL for uploading the CSV file
      const dataCoreApi = DataCoreApiManager.getDataSourcesApi();
      const uploadUrlResponse = await dataCoreApi.handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl(
        this.$store.state.currentOrg.id
      );

      const { uploadUrl, filePointer } = uploadUrlResponse.data;

      if (!uploadUrl) {
        throw new Error('Failed to get upload URL');
      }

      // Step 2: Upload the file to the signed URL
      await axios.put(uploadUrl, this.selectedCsvFile, {
        headers: {
          'Content-Type': 'text/csv',
        },
      });

      // Store the file pointer for creating the data source
      this.csvFilePointer = filePointer;
      this.csvUploadComplete = true;
      this.validate();
    } catch (error: any) {
      console.error('Error uploading CSV file:', error);
      this.errorMessage = error.message || 'Failed to upload CSV file';
    } finally {
      this.isUploadingCsv = false;
    }
  }

  public async createDataSource() {
    this.validate();
    // Clear any previous error messages
    this.errorMessage = '';
    console.log('Cleared error message before creating data source');

    if (this.isValidInput) {
      this.isCreatingDataSource = true;
      try {
        let data = {};
        let createMethod;

        if (this.dataSourceType === 'Google Sheet') {
          data = {
            googlesheet: {
              jaggedRows: this.newDataSourceGoogleSheetJaggedRows,
              name: this.newDataSourceName,
              sheetRange: this.newDataSourceGoogleSheetRange,
              sheetUri: this.newDataSourceGoogleSheetUri,
              skipLeadingRows: this.newDataSourceGoogleSheetSkipLeadingRows,
              type: 'google-sheet',
            },
          };
        } else if (this.dataSourceType === 'Big Query') {
          data = {
            bigquery: {
              table: this.newDataSourceBigQueryTable,
              datasetId: this.newDataSourceBigQueryDataSetId,
              projectId: this.newDataSourceBigQueryProjectId,
              region: this.newDataSourceBigQueryRegion,
              type: 'bigquery',
              name: this.newDataSourceName,
            },
          };
        } else if (this.dataSourceType === 'GCS' && this.hasImportV3Flag) {
          data = {
            gcs: {
              bucket: this.newDataSourceGcsBucket,
              fileId: this.newDataSourceGcsFileId,
              contentType: 'text/csv', // Default to CSV
              jaggedRows: this.newDataSourceGcsJaggedRows,
              name: this.newDataSourceName,
              skipLeadingRows: this.newDataSourceGcsSkipLeadingRows,
              type: 'gcs',
            },
          };
        } else if (this.dataSourceType === 'CSV Upload' && this.hasImportV3Flag) {
          if (!this.csvFilePointer) {
            throw new Error('Please upload a CSV file first');
          }

          data = {
            gcs: {
              bucket: this.csvFilePointer.bucket,
              fileId: this.csvFilePointer.fileId,
              contentType: 'text/csv',
              jaggedRows: this.newDataSourceCsvJaggedRows,
              name: this.newDataSourceName,
              skipLeadingRows: this.newDataSourceCsvSkipLeadingRows,
              type: 'gcs',
            },
          };
        }

        let res;

        if (this.hasImportV3Flag) {
          // Use the DataCoreSvcApi for orgs with import-v3 flag
          const dataCoreApi = DataCoreApiManager.getDataSourcesApi();
          res = await dataCoreApi.handlersDatasourcehdlDataSourceHTTPHandlerCreate(
            this.$store.state.currentOrg.id,
            { data: data as any },
            { withCredentials: true }
          );
        } else {
          // Use the legacy API for orgs without import-v3 flag
          res = await axios.post(
            this.apiBaseUrl + 'v3/orgs/' + this.$store.state.currentOrg.id + '/data-sources',
            {
              data: (data as any).googlesheet || (data as any).bigquery,
            },
            {
              withCredentials: true,
            }
          );
        }

        if ((res.status === 200 || res.status === 201) && (res.data || res.data === null)) {
          this.showSuccessSnackbar('Data Source Created Successfully');
          this.$emit('onCreated');

          // Reset CSV upload state if applicable
          if (this.dataSourceType === 'CSV Upload') {
            this.selectedCsvFile = null;
            this.csvUploadComplete = false;
            this.csvFilePointer = null;
            if (this.$refs.csvFileInput) {
              (this.$refs.csvFileInput as HTMLInputElement).value = '';
            }
          }
        } else {
          this.showErrorSnackbar('Error creating data source');
          console.log('Error creating data source'); // TODO: return and log error
        }

        return res;
      } catch (e: any) {
        console.log('Error creating data source: ', e);

        // Handle API error responses
        console.log('Error response:', e.response);

        if (e.response && e.response.data) {
          console.log('Error response data:', e.response.data);

          if (e.response.data.error) {
            // Display the error message from the API
            this.errorMessage = e.response.data.error;
            console.log('Setting error message from API response:', this.errorMessage);
          } else {
            // Handle case where error might be in a different format
            this.errorMessage = `Error creating data source: ${e.response.status} ${e.response.statusText}`;
            console.log('Setting error message from status:', this.errorMessage);
          }
        } else if (e.message) {
          // Display a generic error message if we can't extract the specific error
          this.errorMessage = `Error creating data source: ${e.message}`;
          console.log('Setting error message from error message:', this.errorMessage);
        } else {
          this.errorMessage = 'An unknown error occurred while creating the data source';
          console.log('Setting default error message:', this.errorMessage);
        }

        // Force Vue to update the UI
        this.$forceUpdate();
      } finally {
        this.isCreatingDataSource = false;
      }
    }
  }
}
